@tailwind base;
@tailwind components;
@tailwind utilities;
 

body {
  margin: 0;
  font-family: 'Rubik';
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-weight: 500;
  color:#353349;
  height:'100%';
  /* overflow-y: hidden !important; */
}

code {
  font-family: 'Rubik';
  font-weight: 500;
}
#mui-component-select-status{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

tspan{
  font-weight: 400;
  font-family: 'Rubik' !important;
  font-size: 13px;
  color:#8E8CA3 !important;
  display:block;
    width:150px;
    word-wrap:break-word;
    
}

.leaflet-container {
  z-index: 1 !important;
}

.leaflet-popup-content-wrapper {
  border-radius: 8px !important;
}

.icon_container {
  height: 30px;
  width: 30px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  top: 32px;
  left: 9px;
}


.leaflet-control-zoom {
  background-color: #ffffff;
  border: 1px solid #E1EBFE;
  border-radius: 5px;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  color: #8E8CA3;
  background-color: #f4f4f4;
  font-size: 10px;
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
  background-color: #e2e2e2;
}