.menu-item {
    color: #000; /* Default color for menu items */
    text-decoration: none;
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: center;

  }
  
  .menu-item.active {
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #004FE8; /* Color for the active menu item */
    color: white;
    font-weight:600;
  }


  .menu-item.noActive {
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: white; /* Color for the active menu item */
    color: #8E8CA3;
    font-weight:600;
  }

  .menu-item.noActive:hover {
    padding: 10px;
    margin: 0 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: #f3f3f3; /* Color for the active menu item */
    font-weight:600;
  }

  .menu-subtitle-item{
    color: #000; /* Default color for menu items */
    text-decoration: none;
    width: 100%;
    /* display: flex; */
    padding: 16px;
    align-items: center;
    width:'100%';

  }

  .menu-subtitle-item.active{
    padding: 16px;
    /* display: flex; */
    width:'100%';
    align-items: center;
    color: #004FE8; /* Color for the active menu item */
    font-weight: 600; /* Apply custom styles for active menu item */
  }

  