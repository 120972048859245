path {
    opacity: 1;
    transition: opacity linear 0.2s 0.1s;
    stroke-width: 1px;
    /* fill: #871539; */
    /* stroke: #a68c66; */
}
path.otherside {
    fill: #f8f8f8;
    stroke: #616161;
    cursor: pointer;
}
path[data-price='0'] {
    fill: #777;
    stroke: #871539;
}
path.greenery {
    fill: green;
    stroke: darkgreen;
}
path[data-price='-1'] {
    fill: #a68c66;
    stroke: #871539;
}