/* body {
    margin: 0 !important;
    font-family: 'Rubik' !important;
} */

:root {
    --primary-color: #004FE8;
    --secondary-color: #8E8CA3;
    --text-black-color: #353349;
    --text-secondary-color: #8E8CA3;
    --text-white-color: #FFFFFF;
    --shadow-black-color : rgba(0, 0, 0, 0.25);
}
 
.top-nav__container {
    width: '100%';
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 4px 0px var(--shadow-black-color);
}

.top-nav__navigations {
   display: flex;
   align-items: center;

}

.top-nav__navigations_items {
   padding: 30px 18px;
}

.top-nav__navigations_items_font_style {
    font-family: 'Rubik';
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    letter-spacing: -0.022em;
    text-align: left;
    color: var(--text-secondary-color);
    cursor: pointer;
}

.top-nav__container_image {
    width: 200px;
    padding: 0px 100px;
}

.top-nav__navigations_button {
    width: 118px;
    height: 47px;
    padding: 14px 16px 14px 16px;
    color: var(--text-white-color);
    border-radius: 4px;
    opacity: 0px;
    background: var(--primary-color);
    margin: 0px 30px 0px 10px;
    border: none;
    font-size: 14px;
}

.section-container__header {
    font-size: 48px;
    font-weight: 500;
    line-height: 56.88px;
    letter-spacing: -0.022em;
    text-align: center;
    color: var(--text-black-color);
    padding: 60px 0px 20px 0px;
}

.section-container__main {
    width: 100%;
    height: 100vh;
}

iframe {
    border: none
}